import React from 'react';
import { ProductBrandingHeader, CobrandedImage, Logo } from '../../ui-widgets';
import { useAppSelector } from '../../../../hooks';
import { RoundImageSizes, selectIsCobranded } from '../../../../slices/common-slice';

import './header-layout.scss';

interface HeaderLayoutProps {
    endItem?: React.ReactNode;
    hideCenteredBranding?: boolean;
    showProductBrandingHeader?: boolean;
    startItem?: React.ReactNode;
}

export const HeaderLayout: React.FC<HeaderLayoutProps> = ({
    endItem,
    hideCenteredBranding = false,
    showProductBrandingHeader,
    startItem,
}): React.ReactElement => {
    const isCobranded = useAppSelector(selectIsCobranded);

    const renderCenteredBranding = () => {
        if (showProductBrandingHeader) {
            return <ProductBrandingHeader />;
        }

        if (isCobranded) {
            return (
                <div className="header-layout__cobranded">
                    <CobrandedImage testId="header-cobranded-image-prizeout" size={RoundImageSizes.TWO} />
                    <CobrandedImage testId="header-cobranded-image-partner" size={RoundImageSizes.TWO} isPartner />
                </div>
            );
        }

        return <Logo />;
    };

    return (
        <header data-testid="header-layout" className="header-layout z-index-header">
            <div className="header-layout__content">
                {startItem && <div className="header-layout__item header-layout__item--start">{startItem}</div>}

                {!hideCenteredBranding && <div className="header-layout__item">{renderCenteredBranding()}</div>}

                {endItem && <div className="header-layout__item header-layout__item--end">{endItem}</div>}
            </div>
        </header>
    );
};
