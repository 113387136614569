import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { createVerification, VERIFICATION_ACTION_TYPES } from '../../../../slices/verification-slice';
import { AppDispatch } from '../../../../store';
import { StatusNotification } from '../../ui-widgets';
import { useAppSelector } from '../../../../hooks';
import { selectPartnerName } from '../../../../slices/partner-config-slice';
import { selectLinkFromEmail } from '../../../../slices/user-slice';
import { ButtonTrackingTypes } from '../../../../utils/services/tracking';

interface AccountLinkRequestProps {
    closeModal: () => void;
}

export const AccountLinkRequest: React.FC<AccountLinkRequestProps> = ({ closeModal }): React.ReactElement => {
    const dispatch = useDispatch<AppDispatch>();
    const partnerName = useAppSelector(selectPartnerName);
    const linkingEmail = useAppSelector(selectLinkFromEmail);
    const [message, setMessage] = useState('');
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const accountLinkRequest = async () => {
        try {
            setIsButtonDisabled(true);
            await dispatch(
                createVerification({
                    actionType: VERIFICATION_ACTION_TYPES.LINK_ACCOUNTS,
                }),
            ).unwrap();
            setIsButtonDisabled(false);
            closeModal();
        } catch (e) {
            setIsButtonDisabled(false);
            toast.error('Email failed to send');
        } finally {
            toast.success('Email sent');
        }
    };

    useEffect(() => {
        setMessage('Do you want to link ' + partnerName + '? Send linking confirmation to ' + linkingEmail + '.');
    }, [partnerName, linkingEmail]);

    return (
        <>
            <StatusNotification
                ariaDescribedById="accountLinkRequest-modal-description"
                ariaLabelledById="accountLinkRequest-modal-headline"
                headline="Link your accounts"
                imageAltText=""
                imageUrl="https://storage.googleapis.com/assets.prizeout.com/widget/verification/link-accounts.png"
                isButtonDisabled={isButtonDisabled}
                message={message}
                primaryButtonText="Send email"
                primaryOnClick={() => accountLinkRequest()}
                trackingTypePrimary={ButtonTrackingTypes.ACCOUNT_LINK_REQUEST_BUTTON_CLICK}
                secondaryButtonText="Cancel"
                secondaryOnClick={() => closeModal()}
                trackingTypeSecondary={ButtonTrackingTypes.ACCOUNT_LINK_CANCEL_BUTTON_CLICK}
            />
        </>
    );
};
