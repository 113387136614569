import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../../hooks/use-app-selector';
import {
    OTPModalView,
    processOTP,
    renewOTPRequestID,
    selectActiveDeliveryPreference,
    selectActiveOtpRequestId,
    setCurrentOTPFlowView,
    setOtpCompleted,
} from '../../../../slices/otp-slice';
import { AppDispatch } from '../../../../store';
import { determineClasses, submitFormOnEnter } from '../../../../utils/helpers';
import { Button } from '../../buttons';
import { Checkbox } from '../../form-components';
import { AlertCallout, AlertCalloutMessageTypes, TextLabel } from '../../ui-widgets';
import { GenericModalLayout } from '../common/generic-modal-layout';
import { CheckboxTrackingTypes, OtpTrackingTypes, TrackingService } from '../../../../utils/services/tracking';
import './enter-verification-code.scss';

interface VerificationCodeForm {
    code: string;
    rememberDevice: boolean;
}

interface EnterVerificationCodeProps {
    confirmOnClick?: () => void;
    onClose?: () => void;
}

export const EnterVerificationCode: React.FC<EnterVerificationCodeProps> = ({
    confirmOnClick,
    onClose,
}): React.ReactElement => {
    const trackingService = TrackingService.getTrackingService();
    const dispatch = useDispatch<AppDispatch>();
    const activeOtpRequestId = useAppSelector(selectActiveOtpRequestId);
    const activeDeliveryPreference = useAppSelector(selectActiveDeliveryPreference);
    const [resendError, setResendError] = useState(false);
    const [resendErrorMessage, setResendErrorMessage] = useState('');

    const deliveryPreferenceMessage = `We sent ${
        activeDeliveryPreference?.type === 'email' ? 'an email' : 'a text message'
    } with a verification code to ${activeDeliveryPreference.value}`;

    const [isLoading, setIsLoading] = useState(false);
    const [isResendLoading, setIsResendLoading] = useState(false);
    const [isResendOTPAvailable, setIsResendoTPAvailable] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setIsResendoTPAvailable(true);
        }, 1000 * 60);
    }, []);

    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
    } = useForm<VerificationCodeForm>({
        mode: 'onBlur',
    });
    const onSubmit: SubmitHandler<VerificationCodeForm> = async (data) => {
        const { code, rememberDevice } = data;

        // dispatch verification notice
        const results: any = await dispatch(
            processOTP({
                code: code,
                otpRequestId: activeOtpRequestId,
                rememberDevice: rememberDevice,
            }),
        );

        if (results.payload.error) {
            dispatch(setOtpCompleted(false));
            setError('code', {
                message: results.payload.message,
            });
        } else if (results.payload.isComplete) {
            setIsLoading(true);
            dispatch(setOtpCompleted(true));
            confirmOnClick();
        }
    };

    const resendOTPCode = async () => {
        try {
            setIsResendLoading(true);
            await dispatch(
                renewOTPRequestID({
                    otpRequestId: activeOtpRequestId,
                }),
            ).unwrap();
            await trackingService.trackOtpClick(OtpTrackingTypes.OTP_SEND_NEW_CODE);
            dispatch(setCurrentOTPFlowView(OTPModalView.SELECT_VERIFICATION_METHOD));
        } catch (e) {
            setResendErrorMessage(e.message);
            setResendError(true);
        } finally {
            setIsResendLoading(false);
        }
    };

    return (
        <div className="enter-verification-code">
            <GenericModalLayout
                ariaLabelledById="enterVerificationCode-modal-headline"
                ariaDescribedById="enterVerificationCode-modal-description"
                hasExitButton={true}
                headline="Enter code"
                onClose={onClose}
                primaryButtonText="Submit code"
                primaryOnClick={handleSubmit(onSubmit)}
                primaryIsLoading={isLoading}
                primaryTestId="process-otp-button"
                subheadline={deliveryPreferenceMessage}
            >
                <form autoComplete="off" className="form">
                    <div className={determineClasses('code', errors)}>
                        <input
                            {...register('code', {
                                required: {
                                    message: 'Verification code is required',
                                    value: true,
                                },
                                pattern: {
                                    message: 'Enter a numeric code',
                                    value: /^[0-9]+$/,
                                },
                            })}
                            aria-required="true"
                            type="text"
                            inputMode="numeric"
                            name="code"
                            id="code"
                            data-testid="code"
                            className="form__input"
                            placeholder="Enter verification code"
                            autoComplete="one-time-code"
                            onKeyDown={(event) => submitFormOnEnter(event, handleSubmit(onSubmit))}
                        />
                        <label className="form__label" htmlFor="code">
                            {errors.code ? errors.code.message : 'Enter verification code'}
                        </label>
                    </div>
                    <div className="enter-verification-code__resend">
                        <TextLabel text="Haven't received your code?" isBold />
                        {resendError && (
                            <AlertCallout
                                type={AlertCalloutMessageTypes.ERROR}
                                message={resendErrorMessage || `An error has occurred resending your code.`}
                            />
                        )}
                        <Button
                            ariaLabel={'Send a new code'}
                            isDisabled={!isResendOTPAvailable}
                            isLink
                            isLoading={isResendLoading}
                            onClick={() => resendOTPCode()}
                            text="Send a new code"
                            testId="resend-otp-code"
                        />
                    </div>
                    <Checkbox
                        register={{
                            ...register('rememberDevice'),
                        }}
                        content="Remember this device for 30 days"
                        id="remember-device"
                        title="Remember device for 30 days"
                        trackingId={activeOtpRequestId}
                        trackingType={CheckboxTrackingTypes.OTP_REMEMBER_DEVICE}
                    />
                </form>
            </GenericModalLayout>
        </div>
    );
};
