import React, { useState } from 'react';
import Classnames from 'classnames';
import { constants } from '../../../utils/constants';
import Skeleton from 'react-loading-skeleton';
import { ClearImageOverlay } from './clear-image-overlay';
import { setImageErrorFallback } from '../../../utils/helpers/image-helpers';

import './gift-card-image.scss';

interface GiftCardImageProps {
    altText?: string;
    imgUrl: string;
    view?: 'hero' | 'detail' | 'confirmation' | 'square';
    renderGradient?: boolean;
}

export const GiftCardImage: React.FC<GiftCardImageProps> = ({
    altText,
    imgUrl,
    view,
    renderGradient,
}): React.ReactElement => {
    const classes: string = Classnames('gift-card-image', { [`gift-card-image--${view}-view`]: view });

    const [isSkeletonVisible, setIsSkeletonVisible] = useState(true);

    const imageOnLoadHandler = () => {
        setIsSkeletonVisible(false);
    };

    const imageOnErrorHandler = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
        setImageErrorFallback(event, constants.defaultGiftCardImageUrl);
        setIsSkeletonVisible(false);
    };
    const imageClassname = Classnames('gift-card-image__image', {
        'gift-card-image__loading': isSkeletonVisible,
    });

    return (
        <div className="offer-highlight">
            <div data-testid="gift-card-image" className={classes}>
                <img
                    className={imageClassname}
                    src={imgUrl || constants.defaultGiftCardImageUrl}
                    alt={altText || ''}
                    onLoad={imageOnLoadHandler}
                    onError={imageOnErrorHandler}
                />
                {isSkeletonVisible && (
                    <Skeleton
                        borderRadius={`var(--border-radius-primary)`}
                        count={1}
                        containerTestId={'gift-card-image-skeleton'}
                        width="100%"
                        height="100%"
                    />
                )}
                <ClearImageOverlay renderGradient={renderGradient} />
            </div>
        </div>
    );
};
