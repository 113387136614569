import React from 'react';
import { HeaderLayout } from './common/header-layout';
import { ExitButton } from '../buttons';
import { ExternalLink, ExternalLinkDisplayTypes } from '../ui-widgets';
import { constants } from '../../../utils';

interface GenericHeaderProps {
    showExit?: boolean;
}

export const GenericHeader: React.FC<GenericHeaderProps> = ({ showExit }): React.ReactElement => {
    const startItem = showExit ? <ExitButton /> : null;
    const endItem = (
        <ExternalLink
            ariaLabel="Go to the help center"
            displayType={ExternalLinkDisplayTypes.LINK}
            label="Contact us"
            url={`${constants.prizeoutWebsiteRoutes.helpUrl}`}
        />
    );

    return <HeaderLayout startItem={startItem} endItem={endItem} />;
};
