import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';
import { sharedAPI } from '../utils/services/api';
import { handleRejectedThunk } from '../utils';

export enum WalletView {
    ARCHIVED_BRANDS = 'archivedBrands',
    ARCHIVED_GIFT_CARDS_BY_BRAND = 'archivedCardByBrand',
    BRANDS = 'brands',
    GIFT_CARDS_BY_BRAND = 'cardByBrand',
}

export enum WalletBrandSortType {
    NEWEST = 'newest',
    OLDEST = 'oldest',
    AMOUNT_HIGH = 'amountHigh',
    ALPHA_ASC = 'alphaAsc',
}

export interface WalletState {
    activeCurrency?: string;
    activeGiftCardRequestId?: string;
    activeBrandId?: string;
    brandAnchor?: string;
    brandViewConfig: BrandViewConfig;
    brands?: WalletBrands;
    giftCardsByBrandId?: BrandCardsHashMap;
    giftCardDetailsByCardId?: GiftCardDetailHashMap;
    lastView?: WalletView;
    view: WalletView;
    walletBrandSort: WalletBrandSortType;
}

const initialBrands: WalletBrands = {
    currencyCode: 'USD',
    walletCurrencies: ['USD'] as string[],
    bonusEarnedCents: 0,
    brands: [] as Brand[],
};

export const walletInitialState: WalletState = {
    activeGiftCardRequestId: null,
    brands: { ...initialBrands },
    brandViewConfig: {},
    giftCardsByBrandId: {} as BrandCardsHashMap,
    giftCardDetailsByCardId: {} as GiftCardDetailHashMap,
    view: WalletView.BRANDS,
    walletBrandSort: WalletBrandSortType.NEWEST,
};

export interface BrandViewConfig {
    showBackButton?: boolean;
}

export interface WalletBrandsRequest {
    archived?: boolean;
    currencyCode?: string;
}

export interface GiftCardsByBrandRequest {
    archived?: boolean;
    brandId: string;
    currencyCode?: string;
}

interface WalletBrands {
    currencyCode: string;
    walletCurrencies: string[];
    bonusEarnedCents: number;
    brands: Brand[];
}

interface WalletBrandsResponse extends WalletBrands {
    archived: boolean;
}

export interface Brand {
    brandId: string;
    brandName: string;
    numArchivedCards: number;
    numActiveCards: number;
    numPendingCards: number;
    totalValueCents: number;
    activeTotalValueCents: number;
    logomarkUrl: string;
    hasPendingCard: boolean;
    lastCashoutDate: string;
    lastCreateDate: string;
}

interface BrandCardsHashMap {
    [brandId: string]: BrandGiftCardsView;
}

interface BrandGiftCardsView {
    brandId: string;
    brandName: string;
    currencyCode: string;
    bonusEarnedCents: number;
    numArchivedCards: number;
    numActiveCards: number;
    totalValueCents: number;
    activeTotalValueCents: number;
    logomarkUrl: string;
    lastRefreshDate: string;
    cards: BrandGiftCards[];
}

export interface BrandGiftCards {
    activeValueCents: number;
    cashOutDate?: string;
    costInCents?: number;
    archiveDate?: string;
    createDate: string;
    giftCardRequestId: string;
    isManualBalanceUpdateAllowed: boolean;
    isArchived?: boolean;
    isPending: boolean;
    last4?: string;
    status: string;
    valueInCents: number;
}

interface GiftCardDetailHashMap {
    [giftCardId: string]: GiftCardDetails;
}

export enum redemptionInstructionsLayout {
    STEPS = 'steps',
    BULLETS = 'bullets',
    TEXT = 'text',
    MARKDOWN = 'markdown',
}

export interface redemptionInstructionsLayoutStep {
    header: string;
    content: string;
}

export interface redemptionInstructionsOption {
    title: string;
    icon: string;
    layout: redemptionInstructionsLayout;
    steps?: redemptionInstructionsLayoutStep[];
    bullets?: string[];
    text?: string;
    markdown?: string;
}

export interface RedemptionInstructionsV2 {
    customAlert?: string;
    dropdowns?: redemptionInstructionsOption[];
    poFAQUrl?: string;
}

export enum GiftCardDetailsStatus {
    ARCHIVED = 'archived',
    ACTIVE = 'active',
    PENDING = 'pending',
}
export enum GiftCardReviewStatus {
    APPROVED = 'approved',
    HELD = 'held',
    PENDING = 'pending',
}

export interface GiftCardDetails {
    archived: boolean;
    barcodeUrl: string;
    barcodeValue: string;
    claimUrl?: string;
    currencyCode: string;
    code: string;
    expirationDate?: string;
    giftCardId: string;
    giftCardName: string;
    giftCardRequestId: string;
    giftCardTerms: string;
    imageUrl: string;
    logomarkUrl?: string;
    pin?: string;
    qrCode?: string;
    redemptionInstructions: string;
    redemptionInstructionsV2?: RedemptionInstructionsV2;
    redirectUrl?: string;
    storeUrl?: string;
    valueInCents: number;
    status?: GiftCardDetailsStatus;
    reviewStatus?: GiftCardReviewStatus;
    partnerName?: string;
}

export const getWalletBrands = createAsyncThunk(
    'wallet/getWalletBrands',
    async ({ currencyCode }: WalletBrandsRequest, { rejectWithValue, signal }) => {
        try {
            const results = await sharedAPI.request({
                data: {
                    currencyCode,
                },
                endpoint: '/wallet/brands',
                method: 'GET',
                signal: signal,
            });

            return {
                ...results.data,
            };
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

export const getGiftCardsByBrandId = createAsyncThunk(
    'wallet/getGiftCardsByBrandId',
    async ({ brandId, currencyCode }: GiftCardsByBrandRequest, { rejectWithValue, signal }) => {
        try {
            const results = await sharedAPI.request({
                data: {
                    currencyCode,
                },
                endpoint: `/wallet/brands/${brandId}/cards`,
                method: 'GET',
                signal: signal,
            });

            return {
                ...results.data,
            };
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

export const getGiftCardsDetails = createAsyncThunk(
    'wallet/getGiftCardsDetails',
    async ({ brandId, giftCardId }: { brandId: string; giftCardId: string }, { rejectWithValue, signal }) => {
        try {
            const results = await sharedAPI.request({
                data: {},
                endpoint: `/wallet/brands/${brandId}/cards/${giftCardId}`,
                method: 'GET',
                signal: signal,
            });

            return {
                ...results.data,
            };
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

export const updateGiftCardData = createAsyncThunk(
    'wallet/updateGiftCardData',
    async (
        { archive, brandId, giftCardId }: { archive: boolean; brandId: string; giftCardId: string },
        { rejectWithValue, signal },
    ) => {
        try {
            const results = await sharedAPI.request({
                data: { archive },
                endpoint: `/wallet/brands/${brandId}/cards/${giftCardId}`,
                method: 'POST',
                signal: signal,
            });

            return {
                ...results.data,
            };
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

export const walletSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(getWalletBrands.fulfilled, (state, action: PayloadAction<WalletBrandsResponse>) => {
            state.brands = action.payload;
        });
        builder.addCase(
            getWalletBrands.rejected,
            handleRejectedThunk<WalletState>((state) => {
                state.brands = initialBrands;
            }),
        );
        builder.addCase(getGiftCardsByBrandId.fulfilled, (state, action: PayloadAction<BrandGiftCardsView>) => {
            state.giftCardsByBrandId[action.payload.brandId] = action.payload;
        });
        builder.addCase(updateGiftCardData.fulfilled, (state, action: PayloadAction<GiftCardDetails>) => {
            state.giftCardDetailsByCardId[action.payload.giftCardRequestId] = action.payload;
        });
        builder.addCase(
            getGiftCardsByBrandId.rejected,
            handleRejectedThunk<WalletState>((state, action: any) => {
                // MRiehle: This can happen if you are not logged in.  The fetch should be
                //          retried on login.
                state.giftCardsByBrandId[action.meta.arg.brandId] = null;
            }),
        );
        builder.addCase(getGiftCardsDetails.fulfilled, (state, action: PayloadAction<GiftCardDetails>) => {
            state.giftCardDetailsByCardId[action.payload.giftCardRequestId] = action.payload;
        });
        builder.addCase(
            getGiftCardsDetails.rejected,
            handleRejectedThunk((state, action: any) => {
                // MRiehle: This can happen if you are not logged in.  The fetch should be
                //          retried on login.
                state.giftCardDetailsByCardId[action.meta.arg.giftCardId] = null;
            }),
        );
    },
    initialState: walletInitialState,
    name: 'wallet',
    reducers: {
        setActiveCurrency(state, action: PayloadAction<string>) {
            state.activeCurrency = action.payload;
        },
        setActiveBrand(state, action: PayloadAction<string>) {
            state.activeBrandId = action.payload;
        },
        setActiveGiftCardRequestId(state, action: PayloadAction<string>) {
            state.activeGiftCardRequestId = action.payload;
        },
        setWalletView(state, action: PayloadAction<WalletView>) {
            // Attempting to change to the same view should always be a noop.
            if (action.payload === state.view) {
                return;
            }

            state.lastView = state.view;
            state.view = action.payload;
        },
        setWalletBrandSort(state, action: PayloadAction<WalletBrandSortType>) {
            state.walletBrandSort = action.payload;
        },
        setBrandAnchor(state, action: PayloadAction<string>) {
            state.brandAnchor = action.payload;
        },
        setBrandViewConfig(state, action: PayloadAction<BrandViewConfig>) {
            state.brandViewConfig = action.payload;
        },
        resetWalletSlice() {
            return walletInitialState;
        },
    },
});

export const {
    setActiveCurrency,
    setActiveBrand,
    setActiveGiftCardRequestId,
    setBrandAnchor,
    setBrandViewConfig,
    setWalletView,
    setWalletBrandSort,
    resetWalletSlice,
} = walletSlice.actions;

const selectWalletState = ({ wallet }: RootState): WalletState => wallet;

export const selectActiveCurrency = createSelector(selectWalletState, ({ activeCurrency }) => activeCurrency);

export const selectActiveBrandId = createSelector(selectWalletState, ({ activeBrandId }) => activeBrandId);

export const selectActiveBrand = createSelector(selectWalletState, ({ brands, activeBrandId }) => {
    const brandList = brands.brands as Brand[];
    return brandList.find((brand: Brand) => {
        return brand.brandId === activeBrandId;
    });
});

export const selectBrandAnchor = createSelector(selectWalletState, ({ brandAnchor }) => brandAnchor);

export const selectWalletBrandSort = createSelector(selectWalletState, ({ walletBrandSort }) => walletBrandSort);

export const selectActiveGiftCardRequestId = createSelector(
    selectWalletState,
    ({ activeGiftCardRequestId }) => activeGiftCardRequestId,
);

export const selectWalletView = createSelector(selectWalletState, ({ view }) => view);

export const selectWalletBrands = createSelector(selectWalletState, ({ brands }) => brands);

export const selectWalletBrandsNonArchive = createSelector(selectWalletBrands, (brandsList) => {
    return {
        ...brandsList,
        brands: [...brandsList.brands.filter((brand) => !!(brand.numActiveCards + brand.numPendingCards))],
    };
});

export const selectWalletBrandsArchive = createSelector(selectWalletBrands, (brandsList) => {
    return {
        ...brandsList,
        brands: [...brandsList.brands.filter((brand) => !!brand.numArchivedCards)],
    };
});

export const selectGiftCardsBrandHashMap = createSelector(
    selectWalletState,
    ({ giftCardsByBrandId }) => giftCardsByBrandId,
);
export const selectBrandViewConfig = createSelector(selectWalletState, ({ brandViewConfig }) => ({
    showBackButton: true,
    ...brandViewConfig,
}));

export const selectListOfGiftCardsByBrand = createSelector(
    selectActiveBrandId,
    selectGiftCardsBrandHashMap,
    (activeBrandId, giftCardsBrandHashMap) => giftCardsBrandHashMap[activeBrandId],
);

export const selectListOfArchivedGiftCardsByBrand = createSelector(selectListOfGiftCardsByBrand, (cardsByBrand) => ({
    ...cardsByBrand,
    cards: cardsByBrand?.cards
        ? cardsByBrand.cards
              .filter((card) => card.isArchived)
              .sort((a: BrandGiftCards, b: BrandGiftCards) => {
                  if (!a.archiveDate || !b.archiveDate) {
                      return 0;
                  }
                  return new Date(b.archiveDate) > new Date(a.archiveDate) ? 1 : -1;
              })
        : [],
}));

export const selectActiveGiftCardDetails = createSelector(
    selectWalletState,
    ({ activeGiftCardRequestId, giftCardDetailsByCardId }) => giftCardDetailsByCardId?.[activeGiftCardRequestId],
);

export const selectGiftCardDetailsByCardId = createSelector(
    selectWalletState,
    ({ giftCardDetailsByCardId }) => giftCardDetailsByCardId,
);

export const selectHasArchivedCards = createSelector(selectWalletState, ({ brands }) => {
    let hasArchivedCards = false;

    brands.brands.forEach((brand) => {
        if (hasArchivedCards) {
            return;
        }

        if (brand.numArchivedCards > 0) {
            hasArchivedCards = true;
        }
    });

    return hasArchivedCards;
});

export const selectLastWalletView = createSelector(selectWalletState, ({ lastView }) => lastView);

export default walletSlice.reducer;
