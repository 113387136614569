import { useState } from 'react';
import SDKMessenger from '../utils/services/sdk';
import { MiniWidgetButtonTypes, TrackingService } from '../utils/services/tracking';
import { routes } from '../utils';

export function useRouteToWidget() {
    const [isDisabled, setIsDisabled] = useState(false);
    const sdkMessenger = SDKMessenger.getSDKMessengerService();
    const trackingService = TrackingService.getTrackingService();

    const openCustomRoute = (route: routes) => {
        sdkMessenger.openToRoute(route);

        enterMarketplace();
    };

    const openOffers = async () => {
        await trackingService.trackMiniWidgetSeeMoreClick(MiniWidgetButtonTypes.EXPLORE_OFFERS_CLICK);

        enterMarketplace();
    };

    const openWallet = async () => {
        await trackingService.trackMiniWidgetSeeMoreClick(MiniWidgetButtonTypes.MY_WALLET_CLICK);

        sdkMessenger.toggleOpenMyGiftCards();

        enterMarketplace();
    };

    const openCashbackWallet = async () => {
        await trackingService.trackMiniWidgetSeeMoreClick(MiniWidgetButtonTypes.MY_CASHBACK_CLICK);

        sdkMessenger.openToRoute(routes.CASHBACK);

        enterMarketplace();
    };

    const enterMarketplace = () => {
        setIsDisabled(true);
        sdkMessenger.seeMore({});
    };

    return {
        isDisabled,
        openCashbackWallet,
        openCustomRoute,
        openOffers,
        openWallet,
    };
}
