import React from 'react';
import { ClickableCard } from './clickable-card';
import { TextLabel } from '../ui-widgets';
import { ClickableHTMLTypes } from '../../../slices/common-slice';
import './action-card.scss';

interface ActionCardProps {
    iconClassList: string;
    description: string;
    heading: string;
    onClick: () => void;
}

export const ActionCard: React.FC<ActionCardProps> = ({
    iconClassList,
    description,
    heading,
    onClick,
}): React.ReactElement => {
    return (
        <ClickableCard
            cardName="action-card"
            disableTracking={true}
            htmlElement={ClickableHTMLTypes.ARTICLE}
            onClick={onClick}
            testId="action-card-clickable"
        >
            <div className="action-card">
                <div className="action-card__icon-wrapper">
                    <i data-testid="action-card-icon" className={iconClassList}></i>
                </div>
                <div className="action-card__content">
                    <TextLabel isBold={true} text={heading} />
                    <p>{description}</p>
                </div>
                <div className="action-card__arrow-wrapper">
                    <i className="fak fa-prizeout-forward-arrow"></i>
                </div>
            </div>
        </ClickableCard>
    );
};
