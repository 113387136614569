import React from 'react';
import { GenericModal } from './generic-modal';

interface ConfirmationModalProps {
    cancelButtonText?: string;
    cancelOnClick: () => void;
    cancelTestId?: string;
    confirmButtonText?: string;
    confirmOnClick: () => void;
    confirmIsLoading?: boolean;
    confirmTestId?: string;
    hasIcon?: boolean; // Has icon in upper left corner of modal
    headline: string;
    iconClasses?: string; // Requires hasIcon, defaults to prizeout-alert-circle icon
    isConfirmationModalOpen: boolean;
    subheadline?: string;
}

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
    cancelButtonText = 'Cancel',
    cancelOnClick,
    cancelTestId = 'confirmation-modal-cancel',
    confirmButtonText = 'Confirm',
    confirmIsLoading,
    confirmOnClick,
    confirmTestId = 'confirmation-modal-confirm',
    hasIcon,
    headline,
    iconClasses,
    isConfirmationModalOpen,
    subheadline,
}): React.ReactElement => {
    return (
        <>
            <GenericModal
                ariaDescribedById={subheadline && `confirmation-modal-description`}
                ariaLabelledById="confirmation-modal-headline"
                ariaLabel="Confirm or cancel a decision"
                hasExitButton={true}
                hasIcon={hasIcon}
                headline={headline}
                iconClasses={iconClasses}
                isOpen={isConfirmationModalOpen}
                onClose={cancelOnClick}
                portalClassName="confirmation-modal"
                primaryButtonText={confirmButtonText}
                primaryOnClick={confirmOnClick}
                primaryTestId={confirmTestId}
                primaryIsLoading={confirmIsLoading}
                secondaryButtonText={cancelButtonText}
                secondaryOnClick={cancelOnClick}
                secondaryTestId={cancelTestId}
                subheadline={subheadline}
                testId="confirmation-modal"
            />
        </>
    );
};
