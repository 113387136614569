import React from 'react';
import Classnames from 'classnames';
import { Button, ButtonColor, ButtonSize, CloseButton } from '../../buttons';
import { RoundImage } from '../../ui-widgets';
import { RoundImageSizes } from '../../../../slices/common-slice';
import { ParagraphSecondary } from '../../typography';

import './generic-modal-layout.scss';

interface GenericModalLayoutProps {
    alertImage?: string; // Requires hasAlertImage
    hasAlertImage?: boolean;
    ariaDescribedById?: string; // This ID needs to match the id on the children's content div or paragraph tag, don't include if no supporting copy. ie. aria-${formName}-description
    ariaLabelledById?: string; // This ID needs to match the id on the children's headline. ie. aria-${formName}-headline
    hasExitButton?: boolean;
    hasIcon?: boolean; // Has icon in upper left corner of modal
    headline?: string;
    iconClasses?: string; // Requires hasIcon, defaults to prizeout-alert-circle icon
    logomarkUrl?: string; // Display brand logo in upper left corner of modal
    onClose?: () => void;
    primaryButtonText?: string;
    primaryIsLoading?: boolean;
    primaryOnClick?: () => void;
    primaryTestId?: string;
    primaryIsDisabled?: boolean;
    secondaryButtonText?: string; // Requires primaryOnClick
    secondaryOnClick?: () => void;
    secondaryTestId?: string;
    subheadline?: string; // Requires headline
}

export const GenericModalLayout: React.FC<GenericModalLayoutProps> = ({
    alertImage,
    hasAlertImage,
    ariaDescribedById,
    ariaLabelledById,
    children,
    hasExitButton,
    hasIcon,
    headline,
    iconClasses = `fak fa-prizeout-alert-circle`,
    logomarkUrl,
    onClose,
    primaryButtonText,
    primaryIsDisabled = false,
    primaryIsLoading,
    primaryOnClick,
    primaryTestId,
    secondaryButtonText,
    secondaryOnClick,
    secondaryTestId,
    subheadline,
}): React.ReactElement => {
    const buttonClasses: string = Classnames('generic-modal-layout__buttons', {
        'generic-modal-layout__buttons--has-secondary': secondaryOnClick,
    });
    const shouldShowControls = hasIcon || hasAlertImage || hasExitButton || logomarkUrl;

    return (
        <div className="generic-modal-layout">
            <>
                {shouldShowControls && (
                    <div className="generic-modal-layout__controls">
                        {hasIcon && <i data-testid="modal-icon" className={iconClasses} />}
                        {hasAlertImage && <img data-testid="modal-alert-image" src={alertImage} alt={headline} />}
                        {logomarkUrl && (
                            <RoundImage
                                testId="generic-modal-layout-round-image"
                                imageUrl={logomarkUrl}
                                size={RoundImageSizes.FIVE}
                            />
                        )}
                        {hasExitButton && <CloseButton onClick={() => onClose()} />}
                    </div>
                )}

                {headline && (
                    <header className="generic-modal-layout__header">
                        <h1 id={ariaLabelledById}>{headline}</h1>
                        {subheadline && <ParagraphSecondary text={subheadline} id={ariaDescribedById} />}
                    </header>
                )}

                {children && <div className="generic-modal-layout__content">{children}</div>}

                {primaryOnClick && (
                    <div className={buttonClasses}>
                        {secondaryOnClick && (
                            <Button
                                ariaLabel={secondaryButtonText}
                                color={ButtonColor.SECONDARY}
                                onClick={secondaryOnClick}
                                size={ButtonSize.MEDIUM}
                                testId={secondaryTestId}
                                text={secondaryButtonText}
                            />
                        )}
                        <Button
                            ariaLabel={primaryButtonText}
                            color={ButtonColor.PRIMARY}
                            isDisabled={primaryIsDisabled}
                            isLoading={primaryIsLoading}
                            onClick={primaryOnClick}
                            size={ButtonSize.MEDIUM}
                            testId={primaryTestId}
                            text={primaryButtonText}
                        />
                    </div>
                )}
            </>
        </div>
    );
};
