import { ConditionOperatorName, TargetOperatorName } from '../types';
import {
    ClosestTargetOperatorArgs,
    ConditionOperatorList,
    ElementAttributeMatchConditionOperatorArgs,
    ElementExistsConditionOperatorArgs,
    ElementHasClassConditionOperatorArgs,
    ElementHasValueConditionOperatorArgs,
    ElementIsEnabledConditionOperatorArgs,
    ElementIsValidConditionOperatorArgs,
    ElementStyleMatchConditionOperatorArgs,
    GetByAttributeValueTargetOperatorArgs,
    GetByTextContentTargetOperatorArgs,
    QuerySelectorTargetOperatorArgs,
    TargetOperatorList,
} from './types';

export const targetOperators: TargetOperatorList = {
    [TargetOperatorName.CLOSEST]: ({ searchElement, selector }: ClosestTargetOperatorArgs): HTMLElement | Document => {
        if (searchElement === document) {
            return document;
        }
        return (searchElement as HTMLElement).closest(selector) as HTMLElement;
    },
    [TargetOperatorName.GET_BY_ATTRIBUTE_VALUE]: ({
        searchElement,
        tagName,
        sourceAttribute,
        targetAttribute,
    }: GetByAttributeValueTargetOperatorArgs): HTMLElement | Document => {
        let sourceElement = searchElement as HTMLElement;

        if (searchElement === document || !sourceElement.getAttribute(sourceAttribute)) {
            sourceElement = document.querySelector(`[${sourceAttribute}]`);
        }

        const targetSelector = `${tagName || ''}[${targetAttribute}="${sourceElement.getAttribute(sourceAttribute)}"]`;

        if (searchElement === document) {
            return searchElement.querySelector(targetSelector) as HTMLElement | Document;
        }

        return searchElement.parentElement.querySelector(targetSelector) as HTMLElement | Document;
    },
    [TargetOperatorName.GET_BY_TEXT_CONTENT]: ({
        searchElement,
        text,
        tagName = 'label',
    }: GetByTextContentTargetOperatorArgs): HTMLElement => {
        return Array.from(searchElement.querySelectorAll(tagName)).find(
            (label) => !!label.textContent.match(new RegExp(text, 'i')),
        ) as HTMLElement;
    },
    [TargetOperatorName.QUERY_SELECTOR]: ({
        searchElement,
        selector,
    }: QuerySelectorTargetOperatorArgs): HTMLElement => {
        return searchElement.querySelector(selector) as HTMLElement;
    },
};

export const conditionOperators: ConditionOperatorList = {
    [ConditionOperatorName.ELEMENT_ATTRIBUTE_MATCH]: ({
        targetElement,
        attributeName,
        value,
    }: ElementAttributeMatchConditionOperatorArgs): boolean => {
        if (targetElement === document) {
            return false;
        }

        return (targetElement as HTMLElement)?.getAttribute(attributeName) === value;
    },
    [ConditionOperatorName.ELEMENT_EXISTS]: ({ targetElement }: ElementExistsConditionOperatorArgs): boolean => {
        return !!targetElement;
    },
    [ConditionOperatorName.ELEMENT_HAS_CLASS]: ({
        targetElement,
        className,
    }: ElementHasClassConditionOperatorArgs): boolean => {
        if (targetElement === document) {
            return false;
        }

        return (targetElement as HTMLElement)?.classList.contains(className);
    },
    [ConditionOperatorName.ELEMENT_HAS_VALUE]: ({
        targetElement,
        value,
    }: ElementHasValueConditionOperatorArgs): boolean => {
        const realTarget = targetElement as HTMLInputElement | HTMLTextAreaElement;

        if (realTarget) {
            return realTarget.value === value;
        }

        return false;
    },
    [ConditionOperatorName.ELEMENT_IS_ENABLED]: ({ targetElement }: ElementIsEnabledConditionOperatorArgs): boolean => {
        if (targetElement === document) {
            return true;
        }

        const realTarget = targetElement as HTMLInputElement | HTMLTextAreaElement;

        if (realTarget) {
            return !realTarget.disabled;
        }

        return true;
    },
    [ConditionOperatorName.ELEMENT_IS_VALID]: ({ targetElement }: ElementIsValidConditionOperatorArgs): boolean => {
        if (targetElement === document) {
            return true;
        }

        const realTarget = targetElement as HTMLInputElement | HTMLTextAreaElement;

        if (realTarget) {
            return realTarget.validity.valid;
        }

        return true;
    },
    [ConditionOperatorName.ELEMENT_STYLE_MATCH]: ({
        targetElement,
        cssStyleName,
        value,
    }: ElementStyleMatchConditionOperatorArgs): boolean => {
        if (targetElement === document) {
            return false;
        }

        return (targetElement as HTMLElement)?.style[cssStyleName] === value;
    },
};
